import callAPI from "@/api";
import { defineStore } from "pinia";

export const useAdStore = defineStore("ad", {
  state: () => ({
    backendUrl: process.env.VUE_APP_API_URL
      ? process.env.VUE_APP_API_URL
      : "http://localhost:3000",
    searchText: "",
    pdfHosting:true,
    pdfDeliver:true,
    pdfPaiement:true,
    ads: [],
    selectedAd: null,
    page: 1,
    limit: 10,
    selection: false,
    notSelected: false,
    rejected: false,
    answered: false,
    response: false,
    isEditingMessage: false,
    isUnreadMessages: false,
    isLoading: false,
    rules: [(v) => v.length <= 1000 || "Max 1000 characters"],
    loadingAdsSearch:false,
    filterOpen:true,
    filterList:[],
  }),
  actions: {
    loadMoreAds: async function ({ done }) {
      if(this.loadingAdsSearch){
        done("empty");
        return;
      }
      let newAds = await this.getAds();
      if (newAds.length === 0) {
        done("empty");
        return;
      }
      this.page += 1;
      this.ads.push(...newAds);
      done("ok");
    },
    checkUnreadMessages(){
      callAPI("/is-unread", "GET").then((res)=>{
        this.isUnreadMessages = res.isUnread;
      }).catch((err)=>{
        console.error(err);
        this.isUnreadMessages = false;
      })
    },
    loadAds: async function () {
      this.loadingAdsSearch = true;
      this.ads = [];
      this.page = 1;
      this.selectedAd = null;
      let newAds = await this.getAds();
      if (newAds.length === 0) {
      this.loadingAdsSearch = false;
        return;
      }
      this.page += 1;
      this.ads.push(...newAds);
      this.loadingAdsSearch = false;
    },
    select(ad) {
      if (!this.isLoading) {
        this.isEditingMessage = false;
        if(ad === this.selectedAd){
          this.selectedAd = null
        } else if(!ad.time || !ad.taxes){
          this.selectedAd ={...ad, taxes: ad.isWithoutTaxes ? "HT" : "TTC", time: ad.isWholeProject ? "Pour le projet" : "Par jour" }
        }  else {
          this.selectedAd = ad;
        }
        if(this.selectedAd && this.selectedAd.unreadMessages){
          callAPI("/ad/read-message", "POST", {adId: this.selectedAd._id}).then(()=>{
            const ad = this.ads.find((ad) =>  ad._id === this.selectedAd._id)
            if(ad){ad.unreadMessages = false}
            const stillUnread = this.ads.some(ad => ad.unreadMessages)
            this.isUnreadMessages = stillUnread;
          })
          .catch((err)=>{
            console.log(err)
          })
        }
      this.pdfDeliver = true;
      this.pdfHosting = true;
      this.pdfPaiement = true;
      }
    },
    getAds: async function () {
      const params = `?search=${this.searchText}&page=${this.page}&limit=${this.limit}&selected=${this.selection}&notSelected=${this.notSelected}&rejected=${this.rejected}&answered=${this.answered}&filters=${JSON.stringify(this.filterList)}&response=${this.response}`;

      return callAPI("/ad" + params, "GET").then((response) => {
        return response.result;
      });
    },
    editSelectedAd(ad) {
      this.selectedAd = ad;
      // edit in list
      this.ads = this.ads.map((a) => {
        if (a._id === ad._id) {
          return ad;
        }
        return a;
      });
    },
  },
});
