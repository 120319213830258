<template>
  <div class="app-bar" v-if="store.filterOpen">
    <v-chip
      class="filter"
      size="small"
      color="white"
      :variant="this.$route.name === 'taken' ? 'flat' : 'tonal'"
      v-if="this.$route.name === 'taken' || this.$route.name === 'not-taken' || this.$route.name === 'eliminated'"
      @click="this.$router.push({ path: '/taken' })"
      prepend-icon="mdi-newspaper"
      >Retenues</v-chip
    >
    <v-chip
      class="filter"
      size="small"
      color="white"
      :variant="this.$route.name === 'not-taken' ? 'flat' : 'tonal'"
      v-if="this.$route.name === 'taken' || this.$route.name === 'not-taken' || this.$route.name === 'eliminated'"
      @click="this.$router.push({ path: '/not-taken' })"
      prepend-icon="mdi-cancel"
      >Non Retenues</v-chip
    >
    <v-chip
      class="filter"
      size="small"
      color="white"
      :variant="this.$route.name === 'eliminated' ? 'flat' : 'tonal'"
      v-if="this.$route.name === 'taken' || this.$route.name === 'not-taken' || this.$route.name === 'eliminated'"
      @click="this.$router.push({ path: '/eliminated' })"
      prepend-icon="mdi-delete"
      >Éliminées</v-chip
    >
    <v-chip
      class="filter"
      size="small"
      color="white"
      :variant="this.$route.name === 'answers' ? 'flat' : 'tonal'"
      v-if="this.$route.name === 'answers' || this.$route.name === 'answered' "
      @click="this.$router.push({ path: '/answers' })"
      prepend-icon="mdi-forum"
      >Réponses</v-chip
    >
    <v-chip
      class="filter"
      size="small"
      color="white"
      :variant="this.$route.name === 'answered' ? 'flat' : 'tonal'"
      v-if="this.$route.name === 'answers' || this.$route.name === 'answered' "
      @click="this.$router.push({ path: '/answered' })"
      prepend-icon="mdi-message"
      >Pas de Réponses</v-chip
    >
    <span class="separator">|</span>
    <v-chip
      v-for="(fil, index) in filters"
      :key="index"
      size="small"
      :variant="isSelected(fil.text) ? 'flat' : 'tonal'"
      class="filter"
      :color="fil.color"
      @click="setFilter(fil.text)"
      >{{ fil.text }}</v-chip
    >
  </div>
</template>

<script>
import { useAdStore } from "@/store.js";
export default {
  setup() {
    const store = useAdStore();
    return { store };
  },
  data() {
    return {
      filters: [
        { text: "Optimisation SEO", color: "green" },
        { text: "Back-end", color: "red" },
        { text: "Application mobile", color: "blue" },
        { text: "Développement spécifique", color: "purple" },
        { text: "Mise en page", color: "orange" },
        { text: "Wordpress", color: "brown" },
        { text: "Android", color: "lime" },
        { text: "iOS", color: "grey" },
        { text: "Création de site internet", color: "pink" },
      ],
    };
  },
  methods: {
    setFilter(name) {
      if (this.store.filterList.find((filter) => filter === name)) {
        this.store.filterList = this.store.filterList.filter((filter) => {
          return filter !== name;
        });
      } else {
        this.store.filterList.push(name);
      }
      this.store.loadAds();
    },
    isSelected(name) {
      if (!this.store.filterList) {
        return false;
      }
      if (this.store.filterList.find((filter) => filter === name)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.separator {
  color: var(--primary);
  margin-left:10px;
}
.app-bar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  background-color: var(--black);
}
.search {
  width: 100%;
  height: 100%;
}
.tabs {
  height: 100%;
  display: flex;
  align-items: center;
}
.link {
  display: flex;
  flex-direction: row;
}
.filter {
  margin: 0 0 0 10px;
}
.search-bar {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 55px;
  min-width: 200px;
}
.header-buttons {
  display: flex;
  width: 200px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.v-tabs .v-tab:not(.v-tab--active) {
  color: var(--secondary);
  font-weight: bold;
}
</style>
