<template>
  <div class="app-bar">
    <v-tabs class="tabs" bg-color="var(--dark)" v-model="selectedTab">
      <v-tab value="taken" color="green" @click="this.$router.push({ path: '/taken' })" prepend-icon="mdi-newspaper">Annonces</v-tab>
      <v-tab value="answers" color="green-accent-4" @click="this.$router.push({ path: '/answers' })" prepend-icon="mdi-forum">Messages
        <div class="unread-bullet" v-if="store.isUnreadMessages"></div>
      </v-tab>
    </v-tabs>

    <div class="search-bar">
      <v-text-field label="Recherche" bg-color="var(--dark)" color="var(--secondary)" 
        base-color="var(--secondary)" class="search" @input="handleInputSearch" @click:clear="clearSearch" v-model="store.searchText" clearable prepend-inner-icon="mdi-magnify"
        variant="solo-filled" />
    </div>
    <div class="header-buttons">
      <v-btn class="force-button" @click="openFilter()" color="var(--black)" icon="mdi-filter" />
      <v-btn class="force-button" @click="forceAds()" color="var(--primary)" icon="mdi-update" />
      <v-btn class="force-button" @click="this.$router.push({ path: '/user-management' })" color="var(--primary)"
        icon="mdi-account" />
      <v-btn class="force-button" @click="disconnect()" color="var(--accent)" icon="mdi-logout" />
    </div>
  </div>
</template>

<script>
import callAPI from '@/api';
import { useAdStore } from '@/store.js';
export default {
  setup(){
    const store = useAdStore();
    return {store}
  },
  data() {
    return {
      searchText: '',
      timer:null,
    };
  },
  computed:{
    selectedTab() {
      const routeName = this.$route.name;
      return routeName === 'taken' || routeName === 'not-taken' || routeName === 'eliminated' ? 'taken' : 'answers';
    },
  },
  methods: {
    handleInputSearch(){
      clearTimeout(this.timer);
      this.timer = setTimeout(this.loadAds, 1000);
    },
    clearSearch(){
      this.store.searchText = '';
      this.store.loadAds();
    },
    loadAds(){
      this.store.loadAds();
    },
    openFilter(){
      this.store.filterOpen = !this.store.filterOpen
    },
    forceAds: function () {
      // reset all variables
      this.store.searchText = "";
      this.store.selectedAd = null;
      this.store.isEditingMessage = false;
      this.store.isLoading = true;
      this.store.page = 1;
      this.store.ads = [];
      callAPI('/force-ads', 'POST')
        .then((response) => {
          console.log(response);
          this.store.page = 1;
          this.store.ads = response.result;
          this.store.isLoading = false;
          this.$router.push({ path: "/taken" });
        });
    },
    disconnect: function () {
      localStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.app-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: var(--dark);
}
.search{
  width:100%;
  height:100%;
}
.tabs {
  height: 100%;
  display: flex;
  align-items: center;
}
.search-bar {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: .8;
  height:55px;
  min-width: 200px;
}
.header-buttons {
  display: flex;
  width: 250px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.v-tabs .v-tab:not(.v-tab--active) {
  color: var(--secondary);
  font-weight: bold;
  
}
.unread-bullet{
  position: absolute;
  width:5px;
  height:5px;
  border-radius: 15px;
  background-color: var(--white);
  box-shadow: 0 0 5px 2px var(--secondary);
  top:10px;
  right:4px;
}
</style>
