async function callAPI(route, method, data) {
  const backendUrl = process.env.VUE_APP_API_URL
    ? process.env.VUE_APP_API_URL
    : "http://localhost:3000";

  const res = await fetch(`${backendUrl}${route}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error("API call failed:", error);
      throw error;
    });
  return res;
}

export default callAPI;
