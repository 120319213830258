import { createRouter, createWebHistory } from "vue-router";
import { useAdStore } from "@/store";
const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: () => import("./views/LoginView.vue"),
    },
    {
      path: "/dashboard",
      component: () => import("./components/CodeurList.vue"),
      meta: {
        header: true,
      },
    },
    {
      path: "/taken",
      name: "taken",
      component: () => import("./views/TakenListView.vue"),
      meta: {
        header: true,
        selection: true,
        notSelected: false,
        rejected: false,
        answered: false,
      },
    },
    {
      path: "/not-taken",
      name: "not-taken",
      component: () => import("./views/NotTakenListView.vue"),
      meta: {
        header: true,
        selection: false,
        notSelected: true,
        rejected: false,
        answered: false,
      },
    },
    {
      path: "/eliminated",
      name: "eliminated",
      component: () => import("./views/EliminatedListView.vue"),
      meta: {
        header: true,
        selection: false,
        notSelected: false,
        rejected: true,
        answered: false,
      },
    },
    {
      path: "/answered",
      name: "answered",
      component: () => import("./views/AnsweredListView.vue"),
      meta: {
        header: true,
        selection: false,
        notSelected: false,
        rejected: false,
        answered: true,
        
      },
    },
    {
      path: "/answers",
      name: "answers",
      component: () => import("./components/CodeurList.vue"),
      meta: { 
        header: true,
        selection: false,
        notSelected: false,
        rejected: false,
        answered: true,
        response: true,
      },
    },
    {
      path: "/user-management",
      component: () => import("./views/UserDashboardView.vue"),
    },
    {
      path: "/forgot-password",
      component: () => import("./views/ForgotPasswordView.vue"),
    },
    {
      path: "/reset-password",
      component: () => import("./views/ResetPasswordView.vue"),
    },
  ],
});

router.beforeEach((to) => {
  const store = useAdStore();
  store.page = 1;
  store.ads = [];
  store.selectedAd = null;
  store.isEditingMessage = false;
  store.isLoading = false;
  store.selection = to.meta.selection || false;
  store.notSelected = to.meta.notSelected || false;
  store.rejected = to.meta.rejected || false;
  store.answered = to.meta.answered || false;
  store.response = to.meta.response || false;
});

export default router;
