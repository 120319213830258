<template>
  <div class="app-content">
    <HeaderComponent v-if="$route.meta.header === true"/>
    <FilterListComponent v-if="$route.meta.header === true"/>
    <router-view class="router-page"></router-view>
    <img class="logo-background" v-if="$route.meta.header === true && !store.selectedAd" src="./assets/ogo scrapper.png" alt="logo">
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FilterListComponent from './components/FilterListComponent.vue';

import { useAdStore } from '@/store.js';
export default {
  setup(){
    const store = useAdStore();

     store.$onAction((e)=>{
      if(e.name === "getAds"){
        store.checkUnreadMessages();
      }
     })

    return {store}
  },
  components: {
    HeaderComponent,
    FilterListComponent,
  },
  computed:{
    filterOpen(){
      if(this.store.filterOpen){
        return 'calc(100% - 90px) !important'
      } else {
        return 'calc(100% - 70px) !important;'
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
.app-content{
  height:100%;
}
html {
  height: 100vh;
  overflow-y: auto !important;
}
body {
  height: 100%;
  background-color: var(--black);
  overflow: hidden;
}
.v-application{
  height: v-bind(filterOpen);
} 
.v-application .v-application__wrap {
  min-height: 100% !important;
}
.logo-background{
  position: fixed;
  width:300px;
  top:50%;
  opacity: .15;
  z-index: 0;
}
</style>
